<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
    @click="onClickCallback"
  >
    <div
      class="content_display"
      v-dompurify-html="value"
    />
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeTextDisplay',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = builder.getCellValue(index, this.cell, this.clone)
      return value || ''
    }
  },
  methods: {
    onClickCallback() {
      if (!this.cell.settings.on_click_callback) {
        return
      }
      const builder = this.data.ChecklistBuilder
      const onCellClicked = builder.getSetting('ON_CELL_CLICKED')
      if (typeof onCellClicked !== 'function') {
        return
      }
      onCellClicked(this.cell)
    }
  }
}
</script>
